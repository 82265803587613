<template>
  <div class="demos">
    <h4>按钮</h4>
    <div class="buttons">
      <my-button type="default" :width="(750 - 90) / 2" disabled>禁用按钮</my-button>
      <my-button type="primary" :width="(750 - 90) / 2">普通按钮</my-button>
    </div>

    <my-button type="primary">提交</my-button>

    <h4>弹窗</h4>
    <my-button @click="dialog" style="margin-bottom: 20px">点击弹窗(icon)</my-button>
    <my-button @click="dialog3" style="margin-bottom: 20px">点击弹窗带cancel</my-button>
    <my-button @click="dialog2">点击弹窗(text)</my-button>

    <h4>位置</h4>
    <get-address title="获取地址" v-model="address"></get-address>

    <get-city title="获取城市" v-model="address2"></get-city>

    <section-title show-asterisk>标题与富文本提示</section-title>
    <notice>
     <p><img :src="require('@/assets/images/icon_gx.png')" alt="">提示提示提示提示提示提示  <b style="color: red"><i>提示提示提示</i></b> 提示提示提示提示提示</p>
    </notice>

    <h4>自适应高度textarea</h4>
    <adaptive-textarea>自适应textarea自适应textarea</adaptive-textarea>

    <h4>图片上传</h4>
    <pic-uploader @change="url=>pic=url" :example="require('@/assets/images/examplePic/example3.png')"></pic-uploader>

    <pic-uploader @change="url=>pic=url" :example="require('@/assets/images/examplePic/example3.png')" :src="require('@/assets/images/examplePic/example3.png')"></pic-uploader>

    <h4>图片上传(只能拍照上传)</h4>
    <pic-uploader @change="url=>pic=url" :example="require('@/assets/images/examplePic/example3.png')"></pic-uploader>

    <h4>视频上传</h4>
    <video-uploader @change="url=>{video=url}"></video-uploader>

    <h4>单元格</h4>
    <cell-input class="order-id" title="普通输入框" placeholder="请输入值" v-model="input1">
    </cell-input>
<!--    <cell-input class="order-id" placeholder="请输入值" v-model="input1">
      <template #title>普通输入框</template>
    </cell-input>-->

    <SelectSearchCell
        title="搜索选择框"
        placeholder="请选择"
        v-model="value1"
        :options="options">
    </SelectSearchCell>
    <SelectSearchCell
        title="远程搜索选择框"
        placeholder="高新曙光国际商家"
        v-model="value2"
        :remote="true"
        @getOptions="getOptions">
    </SelectSearchCell>

    <!-- form表单、下拉底部弹出选择 -->
    <van-form validate-first @submit="onSubmit">
      <select-dia-field
          label="类型"
          name='idTypeText'
          nameId="idType"
          placeholder="请选择"
          v-model="formData.idTypeText"
          :columns="idTypecolumn.idType"
          @getMessage="setSelectValue"
      />

      <multiple-choice title="多选" placeholder="请选择" :options="options2" v-model="selectedOptions"></multiple-choice>

      <money title="金额输入框" v-model="money" placeholder="请输入金额"></money>
      <region-selector></region-selector>

      <scan-code title="新IMEI或序列号" placeholder="请输入手机IMEI号或者扫码" v-model="code"></scan-code>

      <scan-id-card-number></scan-id-card-number>

      <date-picker v-model="date"></date-picker>

        <!-- :rules="[{ validator, message: '请输入正确内容' }]" -->
      <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
  </van-form>
    <h4>多行省略</h4>
    <div class="ellipsis-2">多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略多行省略</div>

    <h4>2×1图片展示</h4>
    <img-display2to1 :src="require('@/assets/images/examplePic/example1.png')"></img-display2to1>

    <h4>协议</h4>
    <protocol v-model="protocol">
      我已阅读并接受<span style="color:var(--base)"><a href="">《亚丁*****协议》</a></span>
    </protocol>
  </div>
</template>

<script>
export default {
  name: "demos",
  data() {
    return {
      value1:'',
      value2:'',
      address: '',
      address2: {},
      code: '',
      pic: '',
      video: '',
      input1: '',
      money: '',
      protocol: '',
      date:'',
      formData:{
          idTypeText:'',
          idType:"",
          xbTypeText:'',
          xbType:"",
      },
      idTypecolumn:{
        idType:[
            {text:"身份证",keyId:"0"},
            {text:"护照",keyId:"2"},
            {text:"军官证",keyId:"3"},
            {text:"港澳居民来往内地通行证",keyId:"5"},
            {text:"其他",keyId:"10"}
        ],
        xbType:[
            {text:"男",keyId:"1"},
            {text:"女",keyId:"0"}
        ],
      },
      options:[
        {label: '高新曙光国际商家',id:0},
        {label: '高新地铁口商家',id:1},
        {label: '高新九方中心商家',id:2},
        {label: '高新理想中心店高新理想中心商家高新理想中心店高新理想中心商家高新理想中心店高新理想中心商家',id:3},
        {label: '高新理想中心店高新理想中心商家',id:4},
        {label: '高新曙光国际商家2',id:5},
        {label: '高新地铁口商家2',id:6},
        {label: '高新九方中心商家2',id:7},
        {label: '高新理想中心店高新理想中心商家高新理想中心店高新理想中心商家高新理想中心店高新理想中心商家2',id:8},
        {label: '高新理想中心店高新理想中心商家2',id:9},
      ],
      options2:[],
      selectedOptions:[]
    }
  },
  mounted() {
    setTimeout(()=>{
      this.options2=[
        {
          name: '屏幕损坏',id:0
        }, {
          name: '后玻璃损坏',id:1
        }, {
          name: '中框断裂',id:2
        }, {
          name: '摄像头损坏',id:3
        }, {
          name: '面容识别损坏',id:4
        }, {
          name: '其他功能性故障',id:5
        }, {
          name: '完好无损',id:6
        },
      ]
    },500)
    this.selectedOptions=[{name:'屏幕损坏',id:0}]
  },
  methods: {
    setSelectValue(name,value){
      console.log('name:',name,'value:',value)
      let a=name.split("Text")[0];
      console.log(a)
      this.formData[a]=value;
    },
    onSubmit(values) {
      this.formData;
      console.log('submit', values,this.formData);
    },


    dialog() {
      this.$myDialog({
        type: 'error',
        title: '未注册',
        message: '首次登陆，需进行门店归属绑定请认真如实填写注册信息，谢谢！',
        confirmButtonText: '确定',
        beforeClose(action, done) {
          if (action === 'confirm') {
            console.log('%c action', 'color:red;font-size:20px', action)
            setTimeout(() => {
              done()
            }, 1000)
          }else {
            done()
          }
        }
      })
    },
    dialog3() {
      this.$myDialog({
        type: 'error',
        title: '未注册',
        message: '首次登陆，需进行门店归属绑定请认真如实填写注册信息，谢谢！',
        isShowCancel:true,
        confirmButtonText: '确定',
        beforeClose(action, done) {
          if (action === 'confirm') {
            console.log('%c action', 'color:red;font-size:20px', action)
            setTimeout(() => {
              done()
            }, 1000)
          }else {
            done()
          }
        }
      })
    },
    dialog2() {
      this.$myDialog({
        type: 'text',
        title: '未注册',
        message: '首次登陆，需进行门店归属绑定请认真如实填写注册信息，谢谢！',
        beforeClose(action, done) {
          if (action === 'confirm') {
            console.log('%c action', 'color:red;font-size:20px', action)
            setTimeout(() => {
              done()
            }, 1000)
          }else {
            done()
          }
        }
      })
    },
    getOptions({value,done}) {
      if (!value) {
        done([])
        return
      }
      setTimeout(()=>{
        done(this.options.filter(item => {
          return item.label.toLowerCase()
              .indexOf(value.toLowerCase()) > -1;
        }))
      },500);
    },

  }
}
</script>

<style scoped lang="less">
.demos {
  padding: 30px;
  width: 100vw;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
</style>
